import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { BaseService } from 'app/services/base.service';
import { catchError, Observable, of, switchMap, throwError, BehaviorSubject } from 'rxjs';

import { environment } from 'environments/environment';
//import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/types/user.type';

import { AppService } from 'app/services/app.service';

const API_URL = environment.backUrl;

@Injectable({providedIn: 'root'})
export class AuthService extends BaseService
{
    public onLogingBeging: BehaviorSubject<any>;
    public onLogingEnd: BehaviorSubject<any>;

    public _authenticated: boolean = false;
    public allowAuth: boolean = true;

    override base_path = 'auth/';

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private userService: UserService,
        //private navigationService: NavigationService,
        override _appService: AppService
    )
    {
        super(_appService);

        this.onLogingBeging     = new BehaviorSubject(null);
		this.onLogingEnd        = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('gt-token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('gt-token') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: any): Observable<any>
    {        
        return this._httpClient.post( API_URL + 'api/auth/recovery/send-email', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: any): Observable<any>
    {
        return this._httpClient.post( API_URL + 'api/auth/recovery/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }):Promise<any> {
        return new Promise((resolve, reject) => {

            let newCredentials = {
                email: credentials.email,
                password: credentials.password
            };

            this._appService.post( this.base_path +  'sign-in', credentials, "" ).then( data => {                
                this.storageData(data);
                resolve(true);
            } ).catch( error => {
                reject(error);
            } )
        })
    }

    /**
     * Sign In using Google
     * @returns 
     */
    SignInWithCoogle( payload: {idToken : string, id: string} = {idToken: "", id: ""} ): Promise<any> {

        return new Promise((resolve, reject) => {
            this._appService.post( this.base_path +  'sign-in-google', payload, "" ).then( data => {                
                this.storageData(data, payload.idToken);
                resolve(true);
            } ).catch( error => {
                reject(false);
            } )
        })
		
	}

    signInAs(payload: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._appService.post( this.base_path +  'sign-in-as', payload, "" ).then( data => {
                this.storageData(data, payload.idToken);
                resolve(true);
            } ).catch( error => {
                reject(false);
            } )
        })
	}

    /**
     * Sign In using Google and APK
     * @returns 
     */
    SignInWithCoogleApk( payload: {idToken : string, id: string} = {idToken: "", id: ""} ): Promise<any> {

        return new Promise((resolve, reject) => {
            this._appService.post( this.base_path +  'sign-in-google-apk', payload, "" ).then( data => {
                this.storageData(data, payload.idToken);
                resolve(true);
            } ).catch( error => {
                reject(false);
            } )
        })
		
	}

    storageData(data: any, google_token: any = null) {
        // Store the access token in the local storage
        this.accessToken = data.token;

        this.userService.user = new User(data.user);
        this.userService.user.google_token = google_token;
        this.userService.scope = data.scope;

        if (this.userService.user.preferences) {
            this.userService.onChangeAccent.next(this.userService.user.preferences.lang_learning);
        }

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        //this._userService.user = data.user;
        //this.navigationService.get();
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Sign in using the token
        return this._httpClient.post(API_URL + 'api/auth/refresh', {
            accessToken: this.accessToken ,
        }).pipe(
            catchError(() =>
                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                let data = response.data;

                if (!data) {
                    return of(false);
                }

                if ( data.token )
                {
                    this.storageData(data);

                    // Return true
                    return of(true);
                }

                

                return of(false)
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {

        this._appService.post( this.base_path +  'sign-out', null, "" ).then( data => {
        } ).catch( error => {
        } )

        // Remove the access token from the local storage
        localStorage.removeItem('gt-token');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */

    /**
     * Sign In using Google and APK
     * @returns 
     */
    signUp( user: User ): Promise<any> {

        return new Promise((resolve, reject) => {
            this._appService.post( this.base_path +  'sign-up', user, "" ).then( data => {
                this.storageData(data, "");
                resolve(true);
            } ).catch( error => {
                reject(error);
            } )
        })
		
	}

    verifyEmail(payload: any) {
        return this._appService.post( "auth/verify-email", payload, "" )
    }

    resendEmailVerificationCode() {
        return this._appService.post( "auth/resent-email-verification-code", null, "" )
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    isAuthenticated(): boolean {
        const token = localStorage.getItem('gt-token');
    
        if (!token) {
            this._authenticated = false;
            return false;
        }
    
        try {
            const decodedToken = this.decodeJwt(token);
            if (!decodedToken) {
                throw new Error('Invalid token');
            }
        
            const currentTime = Math.floor(Date.now() / 1000);
        
            if (decodedToken.exp && decodedToken.exp < currentTime) {
                localStorage.removeItem('gt-token');
                this._authenticated = false;
                return false;
            }
        
            this._authenticated = true;
            return true;
        } catch (error) {
            console.error('Error verifying token:', error);
            localStorage.removeItem('gt-token');
            this._authenticated = false;
            return false;
        }
    }

    private decodeJwt(token: string): any {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        
            return JSON.parse(jsonPayload);
        } catch (error) {
            console.error('Error decoding JWT:', error);
            return null;
        }
    }
}
